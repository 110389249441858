import React from "react"
import {
  DepositWithNetellerQuery,
  DepositWithNettelerFormQuery,
} from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import { SubscriptionPanel, AddFormFields } from "../../components/shared"
import { payNeteller } from "../../actions"

import { Container, Row, Col, Card, Form } from "react-bootstrap"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const { banner_image } = DepositWithNetellerQuery(language)
  const netellerForm = DepositWithNettelerFormQuery(language)
  const { primary, items } = netellerForm.body[0]

  const [formData, updateFormData] = React.useState({})
  const [errMessage, setErrorMessage] = React.useState("")

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    let params = formData
    try {
      let netellerResponse = await payNeteller(params)
      window.location = netellerResponse.result.redirect_url
    } catch (error) {
      // alert('error occured ', error.message)
      setErrorMessage(error.message)
    }

    // ... submit to API or something
  }

  return (
    <>
      <Layout>
        {/* <BreadCrumbBanner heading={page_heading} banner_image={banner_image}/> */}

        <section className="form-background neteller-form-section login-register bgimage biz_overlay overlay--secondary2">
          <div
            className="bg_image_holder"
            style={{ backgroundImage: `url(${banner_image.url})`, opacity: 1 }}
          >
            {/* <img src={banner_image.url} alt="/Content/images/Pages/Demo-Account.png"/> */}
            <div className="content_above">
              <div className="signup-form d-flex align-items-center">
                <Container>
                  <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                      <div className="form-wrapper">
                        <Card>
                          <Card.Header>
                            <h4 className="text-center">
                              {primary.group_title[0].text}
                            </h4>
                          </Card.Header>
                          <Card.Body>
                            <Form onSubmit={handleSubmit}>
                              <AddFormFields
                                data={items}
                                label="none"
                                onChange={handleChange}
                              />
                              <div className="form-group text-center m-top-30 m-bottom-20">
                                <button
                                  className="btn btn-secondary"
                                  type="submit"
                                >
                                  {netellerForm.submit_button_text[0].text}
                                </button>
                              </div>
                            </Form>
                            {errMessage !== "" && (
                              <div className="alert alert-danger" role="alert">
                                {errMessage}
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
